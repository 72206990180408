.custom-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .spinner {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }
  
    .spinner-text {
      margin-top: 10px;
      font-size: 14px;
      color: #333;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }