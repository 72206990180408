footer {
    background-color: #780078;
    color: #fff;
    text-align: left;
    bottom: 0;
    width: 100%;

    .Container {
      padding: 65px 112px 40px 112px;
        .anara-logo-footer {
          margin-bottom: 20px;
          img {
            width: 120px !important;
            height: 32px !important;
          }
        }
    
      .textContent {
        margin-bottom: 5%;
    
        p {
          margin: 0;
        }
      }
    }
}

.footer__links {
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding-right: 20px;
}

.footer__links ul {
    list-style-type: none;
    padding: 0;
}

.footer__links ul li {
    display: inline;
    margin: 0 10px;
}

.footer__links ul li a {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.footer__links ul li a:hover {
    background-color: #555;
    color: #fff; /* Improved accessibility */
}

/* Responsive styles */

@media (max-width: 768px) {
  .footer__links {
    p {
      order: 2;
    }

    nav {
      order: 1;
      ul li {
        flex: 1 0 auto; /* Allow the list items to shrink and grow as necessary */
      }
    }
  }
}

@media (max-width: 576px) {
  footer {
    .Container {
      padding: 48px 20px !important;

      .footer__links {
        display: flex;
        flex-direction: column;
        ul {
          margin-left: -10px;
          li {
            flex: 1 0 auto; /* Allow the list items to shrink and grow as necessary */
            margin: 0;
          }
        } 
      }
      .anara-logo-footer {
        margin-bottom: 20px;
        img {
          width: 100px !important;
          height: 26px !important;
        }
      }

      .textContent {
        margin-bottom: 60px;
      }
    }
  }
}
