.button-class {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    align-self: center;
}

.disabled {
    background-color: #ddd !important;
    color: #595858 !important;
    border: 1px solid #595858 !important;
}
