/* Header.css */

header {
  background-color: #fff;
  color: black;
  text-align: center;
  z-index: 1000;
  position: sticky;
  top: 0;
  height: 80px;
  border-bottom: 1px solid #ADABA5;

  .Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;

    // hack to make the logo layout look nice in landing pages
    >.Container {
      max-width: 500px;
      margin: 0;
      padding-left: 90px;
    }

    .anara-logo {
      img {
        width: 120px;
        height: 32px;
      }
    }
  }
}

header h1 a {
  margin: 0 0 0 90px;
  font-size: 2rem;
  color: #8e108c;
  text-decoration: none;
  font-size: 40px;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -1.2px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  gap: 52px;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: block;
  cursor: pointer;
  padding: 8px;
  font-size: 24px;
}

/* Nav menu styles */
.nav-menu {
  position: fixed;
  top: 80px;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  opacity: 1;
  z-index: 1;

  .Authentication {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 30px;
  }
}

.nav-menu.open {
  transform: translateX(0);
}

.nav-menu ul {
  background: #fff;
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
}

.nav-menu ul li {
  margin: 10px 0 10px 90px;

  a {
    margin-left: 0;
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.72px;
    text-decoration: none;
    cursor: pointer;
  }
}

.signin-button {
  color: #fff;
  background-color: #8e108c;
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.login-button {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Responsive styles */

@media (min-width: 768px) and (max-width: 992px) {
  header  {
    .Container {
      padding-left: 5%;
  
      // hack to make the logo layout look nice in landing pages
      >.Container {
        max-width: 500px;
        padding-left: 0;
      }
  
      .anara-logo {
        img {
        }
      }
    }
  } 
}

@media (max-width: 768px) {
  .nav-menu {
    top: 60px;
  }

  .nav-menu ul li {
    margin: 5px 20px;
  }

  .nav-menu ul li {
    margin: 10px 0 10px 0px;

    a {
      font-size: 30px !important;
      font-weight: 400 !important;
      line-height: 34px;
    }
  }

  .header {
    height: 60px;

    .Container {
      // hack to make the logo layout look nice in landing pages
      >.Container {
        max-width: 120px;
        margin: 0;
        padding-left: 0;
        .AnaraLogo {
          margin-left: 0;
        }
      }
    }

    .nav-menu ul {
      margin-top: 30px;
    }

    .anara-logo {
      margin-left: 2%;

      img {
        width: 120px;
        height: 32px;
      }
    }
  }

  button.sign-out {
    display: none;
  }

  button.sign-up {
    display: flex;
    background: #fff;
    color: #8e108c;
    border: 1px solid var(--primary-600, #8e108c);
    height: 26px !important;
    font-size: 12px !important;
  }

  .menu {
    gap: 13px;

    a.sign-in {
      display: none;
    }
  }

  .hamburger-menu {
    display: block;
  }
}