.CircularChar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: var(--primary-100, #F8E8F8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.cta {
        cursor: pointer;
    }

    span {
        color: var(--primary-900, #330032);
        text-align: center;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
    }
}