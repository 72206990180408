.AnaraLogo {
    cursor: pointer;
    max-width: 120px;

    img {
      width: 120px;
      height: 32px;
    }
}

@media (max-width: 768px) {
    .AnaraLogo {
        margin-left: 0;
        display: flex;

        img {
            width: 80px;
            height: 21px;
        }
    }
}
