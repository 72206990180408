.Authentication {
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 600px) {
        gap: 8px; /* Adjust gap for mobile devices */
    }

    .sign-out, .sign-in {
        width: auto !important;
    }

    &.auth-header-buttons {
        .sign-in {
            color: var(--gray-800, #2E291A);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            min-width: 20ch !important;
            text-decoration: none !important;
        }
    }

    .sign-up {
        min-width: 17ch;
        height: 44px;
    }

    .whatsapp-chat-button {
        background-color: #25D366; /* Bright green color */
        color: #FFFFFF; /* White text */
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        min-width: 10ch;
        text-decoration: none;
        white-space: nowrap;
        border-radius: 8px; /* Rounded corners */
        padding: 10px 20px; /* Padding for better button shape */
        border: none; /* No border */
        cursor: pointer; /* Pointer cursor on hover */

        /* Responsive adjustments */
        @media (max-width: 600px) {
            font-size: 12px; /* Smaller font size for smaller screens */
            padding: 2px 4px; /* Smaller padding */
            min-width: 12ch; /* Adjust minimum width */
            border-radius: 6px; /* Slightly smaller border-radius */
        }
    }
}

.user-data-n-sign-out {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    button.sign-out {
        max-width: 120px;
        text-decoration: none;
        font-weight: 600 !important;
        margin-left: 20px;
    }

    .dropdown-toggle {
        &::after {
            content: '';
            display: none;
        }
        .CircularChar {
            cursor: pointer;
        }
    }

    .dropdown-menu {
        margin-left: -120px;
        margin-top: 5px;
    }
}

.sign-out {
    text-decoration: underline;
}