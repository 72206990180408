@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import '~bootstrap/scss/bootstrap';

.App {
  text-align: center;
  font-family: Open Sans, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 600px;
}

.display-inline {
  display: flex;
  justify-content: space-between;
}

.display-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gap-16 {
  gap: 16px;
}

.gap-32 {
  gap: 32px;
}

.w-max-800 {
  max-width: 800px !important;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-t-56 {
  margin-top: 56px;
}

.m-t-68 {
  margin-top: 68px;
}

.m-t-90 {
  margin-top: 90px;
}


.m-b-16 {
  margin-bottom: 16px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-48 {
  margin-bottom: 48px;
}

/* Margin right*/
.m-r-16 {
  margin-right: 16px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-32 {
  margin-right: 32px;
}

.m-r-48 {
  margin-right: 48px;
}

/* Margin left*/
.m-l-8 {
  margin-left: 8px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-l-48 {
  margin-left: 48px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-t-48 {
  padding-top: 48px;
}

.p-t-56 {
  padding-top: 56px;
}

.p-t-68 {
  padding-top: 68px;
}

.p-t-90 {
  padding-top: 90px;
}


.p-b-16 {
  padding-bottom: 16px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-b-48 {
  padding-bottom: 48px;
}

/* padding right*/
.p-r-16 {
  padding-right: 16px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-48 {
  padding-right: 48px;
}

/* padding left*/
.p-l-8 {
  padding-left: 8px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-32 {
  padding-left: 32px;
}

.p-l-48 {
  padding-left: 48px;
}

/* Global typography styles for H1 titles appearing in common components */
.title {
  color: var(--gray-900, #101828);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -1.2px;
}

.title-h1-60 {
  @extend.title;
  font-size: 60px;
}

/* Global typography styles for H2, H3, H4, H5 subtitles appearing in common components */
.subtitle {
  color: var(--gray-900, #231A09);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}

.subtitle-h2-48 {
  @extend.subtitle;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.subtitle-h2-48-bolder {
  @extend .subtitle-h2-48;
  font-weight: 700;
}

.subtitle-h2-36 {
  @extend.subtitle;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.90px;
}

.subtitle-h2-30 {
  @extend.subtitle;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.88px;
}

.subtitle-h3-20 {
  @extend.subtitle;
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
}

.subtitle-h3-20-bolder {
  @extend.subtitle-h3-20;
  font-weight: 700;
}

.subtitle-h3-16 {
  @extend.subtitle;
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.subtitle-h3-16-bolder {
  @extend.subtitle-h3-16;
  font-weight: 700;
}

.subtitle-h4-24 {
  @extend.subtitle;
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.72px;
}

.subtitle-h4-24-bolder {
  @extend.subtitle-h4-24;
  font-weight: 700;
}

.subtitle-h5-18 {
  @extend.subtitle;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.52px;
}

.subtitle-h5-18-bolder {
  @extend.subtitle-h5-18;
  font-weight: 700;
}

.subtitle-h6-16 {
  @extend.subtitle;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.48px;
}

/* Global typography styles for P tags called descriptions appearing in common components */
.p {
  color: var(--base-black, #000);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.p-30 {
  @extend.p;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.56px;
}

.p-24 {
  @extend.p;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.p-24-bolder {
  @extend.p-24;
  font-weight: 700;
}

.p-20 {
  @extend.p;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.42px;
}

.p-20-bolder {
  @extend .p-20;
  font-weight: 600;
}

.p-18 {
  @extend.p;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.40px;
}

.p-18-bolder {
  @extend .p-18;
  font-weight: 600;
}

.p-16 {
  @extend.p;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.38px;
}

.p-12 {
  @extend.p;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.22px;
}

/* Global typography styles for Span tags called text appearing in common components */
.span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
}

.spanp-36 {
  @extend.span;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.62px;
}

.span-24 {
  @extend.span;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.span-24-bolder {
  @extend.span-24;
  font-weight: 700;
}

.span-20 {
  @extend.span;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.36px;
}

.span-20-bolder {
  @extend.span-20;
  font-weight: 600;
}

.span-18 {
  @extend.span;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.36px;
}

.span-18-bolder {
  @extend.span-18;
  font-weight: 600;
}

.span-16 {
  @extend.span;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.36px;
}

.span-16-bolder {
  @extend.span-16;
  font-weight: 600;
}

.span-14 {
  @extend.span;
  font-size: 14px;
  line-height: 20px;
}

.span-14 {
  @extend.span;
  font-size: 12px;
  line-height: 18px;
}

/* RESPONSIVE DESIGNS */
// Best effort and best guess for tablets and medium screen sizes
@mixin styles-for-sm {
  .title-h1-60 {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-48 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.72px;
  }

  .subtitle-h2-48-bolder {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-36 {
    font-size: 28px;
    line-height: 36px;
  }

  .subtitle-h2-30 {
    font-size: 24px;
    line-height: 32px;
  }

  .subtitle-h4-24,
  .p-24,
  .p-24-bolder {
    font-size: 18px;
    line-height: 26px;
  }

  .subtitle-h5-18 {
    font-size: 16px;
    line-height: 28px;
  }

  .subtitle-h5-18-bolder {
    font-size: 16px;
    font-weight: 700;
  }

  .p-30 {
    font-size: 18px;
    line-height: 26px;
  }

  .p-20,
  .subtitle-h4-24-bolder,
  .subtitle-h5-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .p-20-bolder {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  .span-36 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  .span-30 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.60px;
  }

  .span-24-bolder {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.42px;
  }

  .span-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .span-14 {
    font-size: 14px;
  }
}

// Mixin for medium devices (768px and up)
@mixin styles-for-md {
  @include styles-for-sm;

  .title-h1-60 {
    font-size: 36px;
    line-height: 44px;
  }

  .subtitle-h2-48,
  .subtitle-h2-48-bolder {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-36,
  .subtitle-h2-30 {
    font-size: 24px;
    line-height: 32px;
  }

  .subtitle-h4-24,
  .p-24,
  .p-30,
  .p-24-bolder {
    font-size: 20px;
    line-height: 28px;
  }

  .subtitle-h5-18 {
    font-size: 18px;
  }

  .subtitle-h5-18-bolder {
    font-size: 18px;
    font-weight: 700;
  }

  .p-20,
  .subtitle-h4-24-bolder,
  .span-36 {
    font-size: 24px;
    line-height: 32px;
  }

  .span-30 {
    font-size: 20px;
    line-height: 28px;
  }

  .span-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .span-14 {
    font-size: 14px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .title-h1-60 {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-48 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.72px;
  }

  .subtitle-h2-48-bolder {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-36 {
    font-size: 28px;
    line-height: 36px;
  }

  .subtitle-h2-30 {
    font-size: 24px;
    line-height: 32px;
  }

  .subtitle-h4-24,
  .p-24,
  .p-24-bolder {
    font-size: 18px;
    line-height: 26px;
  }

  .subtitle-h5-18 {
    font-size: 18px;
    line-height: 28px;
  }

  .subtitle-h5-18-bolder {
    font-size: 18px;
    font-weight: 700;
  }

  .p-30 {
    font-size: 18px;
    line-height: 26px;
  }

  .p-20,
  .subtitle-h4-24-bolder,
  .subtitle-h5-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .span-36 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  .span-30 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.60px;
  }

  .span-24-bolder {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.42px;
  }

  .span-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .span-14 {
    font-size: 14px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Include styles for small devices */
  @include styles-for-sm;

  .title-h1-60 {
    font-size: 36px;
    line-height: 44px;
  }

  .subtitle-h2-48,
  .subtitle-h2-48-bolder {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle-h2-36,
  .subtitle-h2-30 {
    font-size: 24px;
    line-height: 32px;
  }

  .subtitle-h4-24,
  .p-24,
  .p-30,
  .p-24-bolder {
    font-size: 20px;
    line-height: 28px;
  }

  .subtitle-h5-18 {
    font-size: 18px;
  }

  .subtitle-h5-18-bolder {
    font-size: 18px;
    font-weight: 700;
  }

  .p-20,
  .subtitle-h4-24-bolder,
  .span-36 {
    font-size: 24px;
    line-height: 32px;
  }

  .span-30 {
    font-size: 20px;
    line-height: 28px;
  }

  .span-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .span-14 {
    font-size: 14px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Include styles for medium devices */
  @include styles-for-md;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .title-h1-60 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }

  .title-h1-48 {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
  }

  .subtitle-h2-48 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.72;
  }

  .subtitle-h2-48-bolder {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }

  .subtitle-h2-36 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  .subtitle-h2-30 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .subtitle-h3-20 {
    @extend.subtitle;
    color: var(--gray-900, #101828);
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
  }

  .subtitle-h3-20-bolder {
    @extend.subtitle-h3-20;
    font-weight: 700;
  }

  .subtitle-h3-16 {
    @extend.subtitle;
    color: var(--gray-900, #101828);
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .subtitle-h3-16-bolder {
    @extend.subtitle-h3-16;
    font-weight: 700;
  }

  .subtitle-h4-24 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .subtitle-h4-24-bolder {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .subtitle-h5-18 {
    font-size: 18px;
    line-height: 28px;
  }

  .subtitle-h5-18-bolder {
    font-size: 18px;
    font-weight: 700;
  }

  .subtitle-h6-16 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .p-30 {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .p-24 {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .p-24-bolder {
    font-weight: 700;
  }

  .p-20 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .p-20-bolder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .p-18 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .p-18-bolder {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .p-16 {
    font-size: 16px;
  }


  .span-36 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  .span-30 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.60px;
  }

  .span-24 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.32px;
  }

  .span-24-bolder {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.32px;
    font-weight: 700;
  }

  .span-18 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .span-18-bolder {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .span-16 {
    font-size: 14px;
    line-height: 20px;
  }

  .span-14 {
    font-size: 12px;
    line-height: 16px;
  }
}


/* Global typography for text colors */
.primary-text {
  color: var(--primary-500, #8E108C);
}

.gray-text-100 {
  color: var(--gray-100, #cecece) !important;
}

.gray-text-200 {
  color: var(--gray-200, #959595) !important;
}

.gray-text-600 {
  color: var(--gray-600, #6E6C65);
}

.gray-text-700 {
  color: var(--gray-700, #4B483F);
}

.green-text-300 {
  color: var(--accent-green-300, #B6D9D6);
}

.white-text {
  color: #FFF;
}

.error-text {
  color: var(--Error-500, #C52948) !important;
}


/* Deprecated - Global typography styles */
.display2xl {
  font-size: 4.5rem;
}

.displayxl {
  font-size: 3.75rem;
}

.displaylg {
  font-size: 3rem;
}

.displaymd {
  font-size: 2.25rem;
}

.displaysm {
  font-size: 1.875rem;
}

.displayxs {
  font-size: 1.5rem;
}

.display2xl-bold {
  font-size: 4.5rem;
  font-weight: bold;
}

.displayxl-bold {
  font-size: 3.75rem;
  font-weight: bold;
}

.displaylg-bold {
  font-size: 3rem;
  font-weight: bold;
}

.displaymd-bold {
  font-size: 2.25rem;
  font-weight: bold;
}

.displaysm-bold {
  font-size: 1.875rem;
  font-weight: bold;
}

.displayxs-bold {
  font-size: 1.5rem;
  font-weight: bold;
}

.alert-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 5%;
}

@media (max-width: 768px) {
  .display2xl {
    font-size: 3rem !important;
  }

  .displayxl {
    font-size: 2.5rem !important;
  }

  .displaylg {
    font-size: 2rem !important;
  }

  .displaymd {
    font-size: 1.5rem !important;
  }

  .displaysm {
    font-size: 1.25rem !important;
  }

  .displayxs {
    font-size: 1.2rem !important;
  }

  .display2xl-bold {
    font-size: 3rem !important;
    font-weight: bold;
  }

  .displayxl-bold {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .displaylg-bold {
    font-size: 2rem !important;
    font-weight: bold;
  }

  .displaymd-bold {
    font-size: 1.5rem !important;
    font-weight: bold;
  }

  .displaysm-bold {
    font-size: 1.25rem !important;
    font-weight: bold;
  }

  .displayxs-bold {
    font-size: 1.2rem !important;
    font-weight: bold;
  }

  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 16px;
      }
    }
  }
}

// Style default elements
.button {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  max-width: 360px;
  padding: 10px 18px;
  gap: 8px;
  display: flex;
  width: 100%;
}

.primary-button {
  @extend .button;
  color: var(--base-white, #FFF);
  border: 1px solid var(--primary-600, #8E108C);
  background: var(--primary-500, #8E108C);
}

.secondary-button {
  @extend .button;
  border: 1px solid var(--primary-500, #8E108C);
  background: var(--base-white, #FFF);
  color: var(--primary-500, #8E108C);
}

.centered-spinner {
  padding: 10px;
  width: 50px;
  display: block;
  height: 50px;
  margin: 0 auto;
}

// Hide the default radio button
input.input-radio[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

// Style the custom SVG container
input.input-radio[type="radio"] + label {
  cursor: pointer;
}

// Style the SVG when not checked
input.input-radio[type="radio"] + label svg {
  fill: #f1efef;
  border: 2px solid #d5d5d5;
  border-radius: 50%;
  transition: fill 0.3s, border-color 0.3s;
}

// Style the SVG when checked
input.input-radio[type="radio"]:checked + label svg {
  fill: #8E108C;
  border-color: #8E108C;
}

.centered-container {
  display: flex;
  width: 80%;
  margin: 2% 5% 2% 15%;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 992px) {
  .centered-container {
    display: flex;
    width: 90%;
    margin: 2% 5%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.hidden {
  display: none !important;
}

.as-link {
  background: none !important;
  color: inherit !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  cursor: pointer !important;
  outline: inherit !important;
  box-shadow: none !important;
}

.purple-link {
  color: var(--primary-500, #8E108C) !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  text-decoration: none;
}

.button-as-link {
  color: var(--primary-500, #8E108C) !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  cursor: pointer !important;
  outline: inherit !important;
  box-shadow: none !important;
  bottom: 0;
  margin-top: auto;
}

.modal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.CustomModal.full-window {
  min-width: 100% !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  min-height: 100% !important;
  margin: 0 !important;

  .modal-content {
    min-height: 100vh !important;
  }

  .CustomModal__Body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    .anara-logo {
      img {
        width: 120px;
        height: 32px;
      }
    }
  }
}


/* Overwrite bootstrap styles */
p.danger, span.danger {
  color: rgb(197, 42, 72);
}
.alert-danger {
  margin: 5px auto 20px auto;
  max-width: 360px;
}

.select-dropdown {
  text-align: left;
}

.strikethrough {
  text-decoration: line-through;
}

/* Honeypot styles */
.fak-field {
  position: absolute;
  left: -9999em;
  height: 1px;
  width: 1px;
  overflow: hidden;
  opacity: 0.01;
}