.Plan {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: 16px;
    // border: 1px solid var(--gray-200, #EAECF0);
    // background: var(--base-white, #FFF);
    // box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    width: 384px;
    max-width: 384px;

    &__header{
        padding: 32px;
        min-height: 475px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        &--title {
            color: var(--primary-500, #8E108C);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
        }

        &--price {
            &--total {
                color: var(--gray-900, #101828);
                font-size: 50px;
                font-weight: 600;
                line-height: 72px;
                letter-spacing: -1.2px;
            }

            &--total-smaller {
                font-size: 40px;
                font-weight: 600;
            }

            &--billing-cycle {
                color: var(--gray-700, #4B483F);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                margin-left: 5px;
            }
        }

        &--description {
            color: var(--gray-600, #6E6C65);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &--start-button {
            display: flex;
            margin-top: auto;
        }
    }

    &.horizontal-plan {
        display: flex;
        width: 100%;
        max-width: none;
        flex-direction: row;
        border-radius: 20px;
        border: 1px solid #918f8a;
        padding: 48px;
        gap: 36px;
        height: 340px;

        .header {
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: flex-start;
            height: 100%;

            .title {
                color: var(--primary-500, #8E108C);
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                align-self: flex-start;
            }

            .description {
                width: 60%;
                align-self: flex-start;
            }

            button {
                align-self: flex-start;
                margin-top: auto;
            }
        }

        .horizontal-plan-info {
            text-align: left;
        }

        .horizontal-plan-features {
            text-align: left;
            ul {
                list-style-type: circle !important;
                width: 500px;
                li {
                    margin-bottom: 0;
                    span {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    &__features {
        border-top: 1px solid var(--gray-200, #EAECF0);
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-content:first baseline;
        
        &--description {
            width: 100%;
            text-align: left;
            color: var(--gray-600, #475467);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &--information {
            text-align: left;
            margin-top: 24px;
            color: var(--gray-700, #4B483F);
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 24px;
            width: 80%;
            margin-top: 16px;

            &.a-le-carte {
                width: 100%;
            }
        }

        &--title {
            color: var(--gray-900, #101828);
            text-align: left;
        }

        &--list {
            list-style-type: none;
            padding: 0;
            align-self: self-start;

            li {
                display: flex;
                min-height: 24px;
                margin-bottom: 10px;

                svg {
                    align-self: center;
                    min-width: 24px;
                    margin-bottom: auto;
                    margin-top: 4px;
                }

               span {
                    line-height: 30px;
                    margin-left: 10px;
                    text-align: left;
               } 
            }
        }
    }
}

@media (max-width: 576px) {

    .custom-plan-container {
        padding: 16px;
        padding-bottom: 32px;
        padding-top: 0;
        margin-top: 0px !important;
    }

    .Plan {
        width: 335px;
        max-width: 335px;
        &__header{
            min-height: 330px;

            &--price {
                &--total {
                    color: var(--gray-900, #101828);
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 42px;
                    letter-spacing: -0.7px;
                }
    
                &--total-smaller {
                    font-size: 36px;
                    font-weight: 600;
                }
            }
        }

        &.horizontal-plan {
            flex-direction: column;
            border: none;
            height: auto;
            border-bottom: 2px solid #ccc9c2;
            border-radius: 0;

            .header {
                display: flex;
                flex-direction: column;
                text-align: left;
                justify-content: flex-start;
                height: 100%;
    
                .title {
                    font-size: 24px;
                }
    
                .description {
                    width: 100%;
                }
    
                button {
                    align-self: flex-start;
                    margin-top: 32px;
                    margin-bottom: 16px;
                }
            }

            .horizontal-plan-features ul {
                margin-bottom: 0;
                width: 300px;
            }
        }

    }
}
